login

<template>
  <div class="login">
    <safe-area>
      <div class="login-area">
        <div class="login-form">
          <div class="tabs">
            <el-tabs v-model="activeName" stretch @tab-click="handleClick">
<!--                <el-tab-pane :label="lang === 'en'?'Mobile':'手机号快捷登录'" name="mobile">-->
<!--                <el-form ref="form" :model="mobileForm" class="form" label-width="80px">-->
<!--                  <el-form-item :label="lang === 'en'?'Mobile':'手机号'">-->
<!--                    <el-input v-model="mobileForm.mobile" :placeholder="lang === 'en'?'input mobile':'请输入手机号'" type="number"></el-input>-->
<!--                  </el-form-item>-->
<!--                  <el-form-item :label="lang === 'en'?'Code':'验证码'">-->
<!--                    <el-input v-model="mobileForm.code" :placeholder="lang === 'en'?'input sms code':'请输入验证码'" type="number">-->
<!--                      <el-button slot="append" class="send-btn" @click="sendMobileCode">-->
<!--                        {{ mobileForm.codeId ? lang === 'en'?'ReSend':'重新发送' : lang === 'en'?'Send':'发送验证码' }}-->
<!--                      </el-button>-->
<!--                    </el-input>-->
<!--                  </el-form-item>-->
<!--                  <el-form-item>-->
<!--                    <div class="treaty" v-if="lang !== 'en'">-->
<!--                      <el-checkbox v-model="checked"></el-checkbox>-->
<!--                      我已阅读并同意<a href="/post/index?type=4">《账号服务条款、隐私政策》</a>-->
<!--                    </div>-->
<!--                    <div class="tips" v-if="lang !== 'en'">*手机号首次登录将为您自动注册</div>-->
<!--                  </el-form-item>-->
<!--                  <el-form-item>-->
<!--                    <el-button type="primary" @click="mobileLogin">-->
<!--                      {{ lang === 'en'?'Login':'立即登录' }}-->
<!--                    </el-button>-->
<!--                  </el-form-item>-->
<!--                </el-form>-->
<!--              </el-tab-pane>-->

              <el-tab-pane :label="lang === 'en'?'Email':'邮箱快捷登录'" name="second">
                <el-form ref="form" :model="emailForm" class="form" label-width="80px">
                  <el-form-item :label="lang === 'en'?'Email':'邮箱号'">
                    <el-input v-model="emailForm.email" :placeholder="lang === 'en'?'input email':'请输入您的邮箱号'" type="email"></el-input>
                  </el-form-item>
                  <el-form-item :label="lang === 'en'?'Code':'验证码'">
                    <el-input v-model="emailForm.code" :placeholder="lang === 'en'?'input eamil code':'请输入验证码'" type="number">
                      <el-button slot="append" class="send-btn" @click="sendEmailCode">
                        {{ emailForm.codeId ? lang === 'en'?'ReSend':'重新发送' : lang === 'en'?'Send':'发送验证码' }}
                      </el-button>
                    </el-input>
                  </el-form-item>
                  <el-form-item>
                    <div class="treaty"  v-if="lang !== 'en'">
                      <el-checkbox v-model="checked"></el-checkbox>
                      我已阅读并同意<a href="/post/index?type=4">《账号服务条款、隐私政策》</a>
                    </div>
                    <div class="tips"  v-if="lang !== 'en'">*邮箱号首次登录将为您自动注册</div>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" @click="emailLogin">
                      {{ lang === 'en'?'Login':'立即登录' }}
                    </el-button>
                  </el-form-item>
                </el-form>
              </el-tab-pane>
              <el-tab-pane v-if="lang !== 'en'" :label="'手机号快捷登录'" name="mobile">
                <el-form ref="form" :model="mobileForm" class="form" label-width="80px">
                  <el-form-item :label="'手机号'">
                    <el-input v-model="mobileForm.mobile" :placeholder="'请输入手机号'" type="number"></el-input>
                  </el-form-item>
                  <el-form-item :label="'验证码'">
                    <el-input v-model="mobileForm.code" :placeholder="'请输入验证码'" type="number">
                      <el-button slot="append" class="send-btn" @click="sendMobileCode">
                        {{ mobileForm.codeId ? '重新发送' : '发送验证码' }}
                      </el-button>
                    </el-input>
                  </el-form-item>
                  <el-form-item>
                    <div class="treaty" v-if="lang !== 'en'">
                      <el-checkbox v-model="checked"></el-checkbox>
                      我已阅读并同意<a href="/post/index?type=4">《账号服务条款、隐私政策》</a>
                    </div>
                    <div class="tips" v-if="lang !== 'en'">*手机号首次登录将为您自动注册</div>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" @click="mobileLogin">
                      {{ lang === 'en'?'Login':'立即登录' }}
                    </el-button>
                  </el-form-item>
                </el-form>
              </el-tab-pane>

            </el-tabs>
          </div>
        </div>
      </div>
    </safe-area>
  </div>
</template>

<script>
import SafeArea from "@/layout/safe-area";
import {loginEmailApi, mobileLoginApi, sendEmailApi, smsApi} from "@/api/account";
import {getLang} from "@/utils/lang";

export default {
  name: "login",
  components: {SafeArea},
  data() {
    return {
      lang: getLang(),
      activeName: 'second',
      checked: true,
      mobileForm: {
        mobile: '',
        code: '',
        codeId: ''
      },
      emailForm: {
        email: '',
        code: '',
        codeId: ''
      }
    }
  },
  methods: {
    mobileLogin() {
      const check = this.mobileFormCheck()
      if (check !== 'success') {
        this.$message.error(check);
        return
      }
      mobileLoginApi({...this.mobileForm}).then(res => {
        localStorage.setItem('token', res.token)
        this.$router.back()
      })
    },
    emailLogin() {
      const check = this.emailFormCheck()
      if (check !== 'success') {
        this.$message.error(check);
        return
      }
      loginEmailApi({
        email: this.emailForm.email,
        codeId: this.emailForm.codeId,
        code: this.emailForm.code
      }).then(res => {
        localStorage.setItem('token', res.token)
        this.$router.back()
      })
    },
    mobileFormCheck() {
      if (!this.checked) {
        return '请先阅读并勾选同意《账号服务条款、隐私政策》'
      }
      if (!this.mobileForm.mobile || this.mobileForm.mobile.length !== 11) {
        return '请输入11位手机号'
      }
      if (!this.mobileForm.codeId) {
        return '请先发送验证码'
      }
      if (!this.mobileForm.code || this.mobileForm.code.length !== 6) {
        return '请输入6位数字短信验证码'
      }
      return 'success'
    },
    emailFormCheck() {
      if (!this.checked) {
        return '请先阅读并勾选同意《账号服务条款、隐私政策》'
      }
      if (!this.emailForm.email) {
        return '请输入您的邮箱号'
      }
      if (this.emailCheck === false) {
        return '邮箱号格式错误'
      }
      if (!this.emailForm.codeId) {
        return '请先发送验证码'
      }
      if (!this.emailForm.code || this.emailForm.code.length !== 6) {
        return '请输入6位数字短信验证码'
      }
      return 'success'
    },
    emailCheck() {
      const emreg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,3}){1,2})$/
      return emreg.test(this.emailForm.email)
    },
    sendEmailCode() {
      if (!this.checked) {
        this.$message.error('请先阅读并勾选同意《账号服务条款、隐私政策》');
        return
      }
      if (!this.emailForm.email) {
        this.$message.error('请输入您的邮箱号');
        return
      }
      if (this.emailCheck() === false) {
        this.$message.error('邮箱号格式错误');
        return;
      }
      // this.emailForm.codeId = 1
      // this.$message({
      //   message: '邮件发送失败，当前阿里云邮模板未通过审核，请提交审核后再试',
      //   type: 'error'
      // });
      sendEmailApi({email: this.emailForm.email}).then(res => {
        this.emailForm.codeId = res.codeId
        this.$message({
          message: '邮件发送成功',
          type: 'success'
        });
      })
    },
    sendMobileCode() {
      if (!this.checked) {
        this.$message.error('请先阅读并勾选同意《账号服务条款、隐私政策》');
        return
      }
      if (!this.mobileForm.mobile || this.mobileForm.mobile.length !== 11) {
        this.$message.error('请输入11位手机号');
        return
      }
      smsApi({mobile: this.mobileForm.mobile}).then(res => {
        this.mobileForm.codeId = res.codeId
        this.$message({
          message: '短信发送成功',
          type: 'success'
        });
      })
    }
  }
}
</script>

<style scoped>
.login {
  background: url("../../assets/login/login.jpeg") top center no-repeat;
  background-size: 100% auto;
}

.login-area {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 80px 0;
}

.login-form {
  flex: 0 0 400px;
  width: 400px;
  height: 500px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 1px 1px 10px 5px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}

.tabs {
  padding: 40px 30px;
}

.form {
  padding: 50px 0;
}

.tips {
  color: #999999;
  font-size: 0.6rem;
}

.treaty {
  font-size: 0.7rem;
  color: #888888;
}

.treaty a {
  color: #409EFF;
}

.send-btn {
  font-size: 0.7rem;
}
</style>
